import $ from 'jquery';

import React from 'react';
import {render} from 'react-dom';

//redux store
import configureStore from '../../../App/js/dataStore/configureStore'; 

import IndexColumn from './indexColumn';
import IndexImageContainer from './indexImageContainer';

//
let outputObjId="",loadKey=-1;

//column vars to try adn even out the columns
let columns=4; //default number of columns, unless user_settings are specified
let columnCounts=[]; //used to create ID's for react to render in
let columnHeights=[]; //keep track of the heights so that the columns can be even heights
let inView=false;

//global vars to check and sanitize(TO-DO)
let searchInput='';
let sortTypeInput='';
let sortOrderInput='';
let searchVarsSet = false;

let imageQueue=[]; //array to keep image data in and to unshift it (liek a queue) to load images
let checkImageQueueInterval; //actual interval to check image queue
let totalImages=0; //total iamges loaded, to be used as an pointer for queries
let fails=0; //number of times ajax request has failed
let maxFails=5; //max number of times the ajax request can fail
let gettingImages=false; //see if currently requesting iamge data

let endOfImageQuery=false; //stops the queueInterval if there are now more rows from query

const store = configureStore();
let user=store.getState().reducer_user.user;

export default class ImageColumnLoader {
	

	
	constructor(objId="",key=-1){

		outputObjId = objId;
		loadKey = key;
		this.getSearchValues=this.getSearchValues.bind(this);
		this.checkImageQueue=this.checkImageQueue.bind(this);
		this.getImages=this.getImages.bind(this);
		this.addImageContainer=this.addImageContainer.bind(this);
		this.createColumns=this.createColumns.bind(this);
		this.findShortestColumn=this.findShortestColumn.bind(this);
		this.newSearch=this.newSearch.bind(this);
		this.stopQueueInterval=this.stopQueueInterval.bind(this);

		this.setSearchInput=this.setSearchInput.bind(this);
		this.setSortTypeInput=this.setSortTypeInput.bind(this);
		this.setSortOrderInput=this.setSortOrderInput.bind(this);


	}


	//-----------------------------------Everything to load images dynamically-----------------------------------//
	//-----------------------------------------------------------------------------------------------------------//

	
	checkImageQueue(){
		//console.log('queue:'+imageQueue.length+"    gettingImages:"+gettingImages+"     fails:"+fails+"      total:"+totalImages);

		//If failed to retrieve images from back-end then just stop
		if(fails>=maxFails){
			clearInterval(checkImageQueueInterval);
			console.log('    !!!FAILED!!!');
		//if there are images in the queue, then load them
		}else if(imageQueue.length>=1){
			//If the view is in range, then load the images in
			if(inView){
				let image=imageQueue.pop();
				this.addImageContainer(image,this.findShortestColumn());
			//If the view isn't in range, then just check if it is or not
			}else{
				let columnBottoms=document.getElementsByClassName('imageColumnBottom');
				let loadHeight=document.documentElement.scrollTop+document.documentElement.clientHeight*2.2;
				for(let i=0;i<columnBottoms.length;i++){
					let h=columnBottoms[i].getBoundingClientRect().y;
					if(h<loadHeight){
						inView=true;
						break;
					}
				}
			}
		//If there are no more images to retrieve then stop
		}else if(endOfImageQuery){
			this.stopQueueInterval();
		//if there aren't any images in the queue, then get some
		}else if(imageQueue.length<=1){
			inView=false;
			this.getImages(totalImages,20);
		//If here, something isn't right
		}else{
			console.log('doing nothing....');
		}
	}

	getImages(index=0,total=20){
		if(!gettingImages){
			gettingImages=true;
			let tempKey=loadKey;
			//console.log(tempKey+'    Trying to get some... index:'+index+"  total:"+total);
			//console.log("searchText="+searchInput);
			$.get({
				url:'/index/images',
				data:{index:index,total:total,searchText:searchInput,whatInput:sortTypeInput,orderInput:sortOrderInput},	
				success:function(data){
					console.log(data);
					if(data==='The image array is empty'){
						endOfImageQuery=true;
					}else if(data.length===0||data===null||data===''){
						//console.log("    FAILED bad DATA:'"+data+"'");
						fails++;
					}else if(tempKey===loadKey){ //if the key changed between requesting the data and recieving it, then a new search was done
						let images=data;
						//console.log('    GOT SOME! n='+images.length);
						fails=0;
						for(let i=0;i<images.length;i++){
							images[i].loadKey=tempKey;
							imageQueue.unshift(images[i]);
							totalImages++;
						}
						if(images.length<total)
							endOfImageQuery=true;
					}
					gettingImages=false;
				},
				error:function(){
					//console.log('    FAILED request:'+fails);
					fails++;
					gettingImages=false;
				}
			});
		}
	}


	addImageContainer(image,column){
		//console.log("image key "+image.loadKey+" =? "+loadKey);
		if(image.height>0&&image.loadKey==loadKey){//Checking if the key has changed again, to make sure that the images are the ones that the user searched for, not from the previous search
			columnHeights[column]+=image.height;
			let tempID="Image_"+column+""+columnCounts[column];
			//console.log("Adding '"+tempID+"' to Column="+column);
			let imageContainer=document.createElement('div');
			imageContainer.id=tempID;
			$('#imageColumn'+column).append(imageContainer);
			columnCounts[column]++;
			console.log(image);
			render(<IndexImageContainer image={image} tempID={tempID} columns={columns}/>,document.getElementById(tempID));
		}
	}


	//------------------------------------Helper Functions--------------------------------------------------------//
	//------------------------------------------------------------------------------------------------------------//


	//Create columns based on desktop or mobile
	createColumns(){
		columns=user.column_count;
		if (window.innerWidth<600)
			columns=user.column_count_mobile;
		// let columnWidth=(100-(columns+1))/columns;
		// let style={width:columnWidth+"%"};
		render(<IndexColumn num={columns}/>,document.getElementById(outputObjId));
		for(let i=0;i<columns;i++){
			columnCounts.push(0);
			columnHeights.push(0);
		}
	}

	findShortestColumn(){
		//console.log(columnHeights);
		let shortest=columnHeights[0];
		let index=0;
		for(let i=1;i<columns;i++){
			if(columnHeights[i]<shortest){
				shortest=columnHeights[i];
				index=i;
			}
		}
		return index;
	}

	newSearch(key=-1,updateSpeed=15){
		loadKey=key;
		//stop current interval check 
			this.stopQueueInterval();
		//clear columns
		for(let i=0;i<columns;i++){
			$('#imageColumn'+i).html('');
			columnCounts[i]=0;
			columnHeights[i]=0;	
		}
		//reset vars
		fails=0;
		imageQueue=[];
		totalImages=0;
		gettingImages=false;
		endOfImageQuery=false;
		if(!searchVarsSet)
			this.getSearchValues();
		else
			searchVarsSet=false;

		//Start check
		let checkQueue=this.checkImageQueue
		checkImageQueueInterval=setInterval(checkQueue,updateSpeed);
	}
	
	getSearchValues(){
		searchInput=$('#BannerSearchText').val();
		sortTypeInput=$('#BannerSearchSortType').children('.select-selected')[0].innerHTML;
		sortOrderInput=$('#BannerSearchSortOrder').children('.select-selected')[0].innerHTML;
		imageQueue=[];
	}

	setSearchInput(text=""){
		searchVarsSet=true;
		
		searchInput=text;
	}
	setSortTypeInput(text=""){
		searchVarsSet=true;
		sortTypeInput=text;
	}
	setSortOrderInput(text=""){
		searchVarsSet=true;
		sortOrderInput=text;
	}
	
	
	stopQueueInterval(){
		clearInterval(checkImageQueueInterval);
	}





}

