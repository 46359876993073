import React,{Component} from 'react';
//import $ from 'jquery';

export default class IndexColumn extends Component{

	render(){

		let columns=[];
		let width=100/this.props.num;
		let style={width:width+"%"};
		for(let i=0;i<this.props.num;i++){
			columns.push(<div id={'imageColumnContainer'+i} className='imageColumnContainer' style={style} key={i}>
				<div id={'imageColumn'+i} className="imageColumn"></div>
				<div className='imageColumnBottom'></div>
			</div>);
		}

		return (<div id='ColumnContainer'>{columns}</div>);
	}

}