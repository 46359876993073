import React,{Component} from 'react';
import $ from 'jquery';

//redux store
import configureStore from '../../../App/js/dataStore/configureStore'; //'App/js/dataStore/configureStore';

import sendLike from '../../../App/js/sendData/sendLike';
import {popupShowTextLogin} from '../../../App/js/popups/popup.js';

const store = configureStore();
let user=store.getState().reducer_user.user;

export default class indexColumn extends Component{

	constructor(props){
		super(props);
		this.state={
			loadState:'loading',
			altText:'',
		};

		this.imageLoaded=this.imageLoaded.bind(this);
		this.imageError=this.imageError.bind(this);
		this.user=store.getState().reducer_user.user;
	}

	imageLoaded(){
		this.setState({loadState:'loaded'});
	}
	imageError(){
		this.setState({altText:"ERROR:Couldn't find dog"});
	}
	

	render(){
		let titleClassName='imageTitle';
		let image = this.props.image;
		let tempID = this.props.tempID;
		if(image.title.length>25)
			titleClassName+=' longTitle';
		let imageWidths=[200,320,640];
		let backupFullURL='/pictures/'+image.directory+'/'+image.FileName+'.'+image.Extension;
		
		let trueWidth=(window.screen.width * window.devicePixelRatio);
		//console.log(trueWidth+" "+window.devicePixelRatio);
		// if(trueWidth>1000)
		// 	trueWidth=1000;
		let widthRatio = (window.screen.width/trueWidth) * .8;
		//console.log(trueWidth+" - "+widthRatio);


		let initialPatClass='',initialBoopClass='';
		let likes= {'pat':0,'boop':0};
		
		if(image.user_pat==1){
			likes['pat']=1;
			initialPatClass='liked'
		}
		if(image.user_boop==1){
			likes['boop']=1;
			initialBoopClass='liked'
		}

		//change the classes of the like iamges
		function like(type){
			if(user.username!==null){
				if(likes[type]===1){
					likes[type]=0;
					$("#"+tempID+"_"+type).removeClass('liked');
				}else{
					likes[type]=1;
					$("#"+tempID+"_"+type).addClass('liked');
				}
				sendLike(type,image.directory,image.FileName,likes[type]);
			}else
				popupShowTextLogin();
		};

		let sources=[];
		if(this.props.columns===undefined)
			var columns=4;
		else
			columns=this.props.columns;
		//create the source elements so that it can't load in the correct resolution for the screen
		if(image.Extension==="jpg"||image.Extension==="jpeg"){
			sources.push(
				<source srcSet={'/pictures/'+imageWidths[0]+'/'+image.directory+'/'+image.FileName+'.webp'} type='image/webp' key={0}></source>,
				<source srcSet={'/pictures/'+imageWidths[0]+'/'+image.directory+'/'+image.FileName+'.'+image.Extension} type={'image/'+image.Extension} key={1} ></source>,
				<img className={this.state.loadState} src={backupFullURL} onError={this.imageError} onLoad={this.imageLoaded} alt={this.state.altText} loading="lazy" key={-1}></img>
			);
			for(let i=1;i<imageWidths.length;i++){
				sources.splice(0,0,
					<source media={"(min-width:"+(imageWidths[i-1]*this.props.columns)*widthRatio+"px)"} srcSet={'/pictures/'+imageWidths[i]+'/'+image.directory+'/'+image.FileName+'.webp'} type='image/webp' key={i*2}></source>,
					<source media={"(min-width:"+widthRatio+"px)"} srcSet={'/pictures/'+imageWidths[i]+'/'+image.directory+'/'+image.FileName+'.'+image.Extension} type={'image/'+image.Extension} key={i*2+1} ></source>,
				);
			}
		}else if(image.Extension==="gif"){
			// sources.push(
			// 	<source srcSet={'/pictures/'+imageWidths[0]+'/'+image.directory+'/'+image.FileName+'.'+image.Extension} type={'image/'+image.Extension} key={0} ></source>,
			// );
			// for(let i=1;i<imageWidths.length;i++){
			// 	sources.splice(0,0,
			// 		<source media={"(min-width:"+(imageWidths[i-1]*this.props.columns)*widthRatio+"px)"} srcSet={'/pictures/'+imageWidths[i]+'/'+image.directory+'/'+image.FileName+'.'+image.Extension} type={'image/'+image.Extension} key={i} ></source>,
			// 	);
			// }
			let w=0;//try and get a somewhat okay res for the screen
			for(let j=1;j<imageWidths.length;j++){
				if((imageWidths[j]*this.props.columns)*widthRatio < trueWidth){
					w=j;
				}else
					break;
			}
			sources=[
		 		<video playsInline={true} loop={true} autoPlay={true} draggable={false} muted={true} key={0}>
					 <source type="video/mp4" src={'/pictures/'+imageWidths[w]+'/'+image.directory+'/'+image.FileName+'.mp4'}  ></source>
					 {/* <source srcSet={'/pictures/'+imageWidths[0]+'/'+image.directory+'/'+image.FileName+'.'+image.Extension} type={'image/'+image.Extension} key={1} ></source> */}
				 </video>,
			];
		}


		//Add parent icon
		let parentElement=[];
		if(image.parent>0){
			parentElement=<img className="multipleImageIcon" src="/build/images/multipleImagesIcon.png"></img>;
		}

		return (<div className={'imageContainer '+this.user.dark_mode}>
				<div className={titleClassName}>{image.title}</div>

				{parentElement}

				<a href={'/dog/'+image.directory+'/'+image.FileName}>
					<picture className='imagePicture'>
						{sources}
						{/* <img className={this.state.loadState} src={backupFullURL} onError={this.imageError} onLoad={this.imageLoaded} alt={this.state.altText} loading="lazy"></img> */}
					</picture>
				</a>

				<div className='imageLikesContainer'>

					<div id={tempID+"_pat"} className={"DogPatsImg dogLikesImg"+" "+initialPatClass} onClick={function(){console.log(this);like('pat');}}>

						<img alt="Pats"></img>
					</div>
					<div id="DogPatsCount" className="dogLikesCount">{image.pats}</div>

					<div id={tempID+"_boop"} className={"DogBoopsImg dogLikesImg"+" "+initialBoopClass} onClick={function(){like('boop');}}>

						<img alt="Boops"></img>
					</div>
					<div id="DogBoopsCount" className="dogLikesCount">{image.boops}</div>

				</div>
		
			</div>);
	}

}

